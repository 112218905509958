import React from 'react';
import { StyledPaymentIcons } from './styled';
import { Image } from '~/shared/components';
import { useFrame } from '~/shared/utils';

type Props = {
    center?: boolean;
};

export const PaymentIcons = ({ center }: Props) => {
    const { data: frame } = useFrame();
    return (
        <StyledPaymentIcons center={center}>
            {frame?.ecommerce?.paymentIcons?.map(
                ({ image, title }, index) =>
                    image?.src && (
                        <li key={image.src + index}>
                            <Image
                                src={image.src}
                                title={title}
                                alt={title}
                                width={35}
                                height={24}
                                layout="intrinsic"
                                objectFit="contain"
                            />
                        </li>
                    )
            )}
        </StyledPaymentIcons>
    );
};
